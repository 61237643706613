<template>
  <div class="assessment-report__charts d-flex">
    <div class="row vdivide w-100">
      <div class="col-md-6 col-sm-12">
        <div class="position-relative">
          <h3
            class="headerBorder mb-4"
            v-if="isResponse && assessments.mcq_assessment"
          >
            <span> {{ $t("assesmentReport.mcq") }} </span>
          </h3>
          <div
            class="d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="300"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>

        <div class="d-flex" v-if="isResponse && assessments.mcq_assessment">
          <div class="w-50">
            <ul class="registration-talents">
              <li>
                <span
                  class="registration-talents__value registration-talents__workable"
                  >{{ assessments.mcq_assessment.right_answers_count }}</span
                >
                <span class="registration-talents__key">
                  {{ $t("assesmentReport.rightAns") }}
                  {{ assessments.mcq_assessment.percentage.toFixed(1) }}%</span
                >
              </li>
              <li>
                <span
                  class="registration-talents__value registration-talents__signup"
                  >{{
                    assessments.mcq_assessment.questions_count -
                    assessments.mcq_assessment.right_answers_count
                  }}</span
                >
                <span class="registration-talents__key">
                  {{ $t("assesmentReport.wrongAns") }}
                  {{
                    (100 - assessments.mcq_assessment.percentage).toFixed(1)
                  }}%</span
                >
              </li>
            </ul>
          </div>
          <div class="w-50">
            <DoughnutRegistrationTalents
              class="foo-chart"
              :width="240"
              :height="240"
              :rightAns="assessments.mcq_assessment.right_answers_count"
              :wrongAns="
                assessments.mcq_assessment.questions_count -
                assessments.mcq_assessment.right_answers_count
              "
              :loading="false"
            />
          </div>
        </div>

        <div class="d-flex flex-column" v-if="!isResponse">
          <div class="d-flex align-items-center justify-content-start mt-4">
            <skeleton-loader-vue
              type="rect"
              :width="500"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
          <div class="d-flex align-items-center justify-content-start mt-4">
            <skeleton-loader-vue
              type="rect"
              :width="500"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
          <div class="d-flex align-items-center justify-content-start mt-4">
            <skeleton-loader-vue
              type="rect"
              :width="500"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="position-relative">
          <h3
            class="headerBorder mb-4"
            v-if="isResponse && assessments.code_assessment"
          >
            <span> {{ $t("assesmentReport.problemSolving") }}</span>
          </h3>
          <div
            class="d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="300"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>

        <div class="d-flex" v-if="isResponse && assessments.code_assessment">
          <div class="w-50">
            <ul class="registration-talents">
              <li>
                <span
                  class="registration-talents__value registration-talents__workable"
                  >{{ assessments.code_assessment.right_answers_count }}</span
                >
                <span class="registration-talents__key">
                  {{ $t("assesmentReport.rightAns") }}
                  {{ assessments.code_assessment.percentage.toFixed(1) }}%</span
                >
              </li>
              <li>
                <span
                  class="registration-talents__value registration-talents__signup"
                  >{{
                    assessments.code_assessment.questions_count -
                    assessments.code_assessment.right_answers_count
                  }}</span
                >
                <span class="registration-talents__key">
                  {{ $t("assesmentReport.wrongAns") }}
                  {{
                    (100 - assessments.code_assessment.percentage).toFixed(1)
                  }}%</span
                >
              </li>
            </ul>
          </div>
          <div class="w-50">
            <DoughnutRegistrationTalents
              class="foo-chart"
              :width="240"
              :height="240"
              :rightAns="assessments.code_assessment.right_answers_count"
              :wrongAns="
                assessments.code_assessment.questions_count -
                assessments.code_assessment.right_answers_count
              "
              :loading="false"
            />
          </div>
        </div>
        <div class="d-flex flex-column" v-if="!isResponse">
          <div class="d-flex align-items-center justify-content-start mt-4">
            <skeleton-loader-vue
              type="rect"
              :width="500"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
          <div class="d-flex align-items-center justify-content-start mt-4">
            <skeleton-loader-vue
              type="rect"
              :width="500"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
          <div class="d-flex align-items-center justify-content-start mt-4">
            <skeleton-loader-vue
              type="rect"
              :width="500"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutRegistrationTalents from "./DoughnutRegistrationTalents.vue";
export default {
  components: {
    DoughnutRegistrationTalents,
  },
  props: {
    isResponse: {
      type: Boolean,
    },
    assessments: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.assessment-report__charts {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 3%);
  position: relative;
  padding: 20px;
  margin: 0px;
  margin-top: 30px;
  width: 100%;
  flex-direction: row;
  align-items: center;

  .row.vdivide [class*="col-"]:not(:last-child):after {
    background: #e6e4e4;
    width: 1px;
    content: "";
    display: block;
    position: absolute;
    top: 5rem;
    bottom: 0;
    right: 53.5%;
    min-height: 52px;
    display: flex;
    max-height: 12rem;
    align-items: center;
    display: none;

    @media (max-width: 576px) {
      display: none;
    }
  }

  .registration-talents {
    margin-top: 3rem;

    @media (max-width: 576px) {
      margin-top: 2rem;
      padding-left: 0;
    }

    li:first-of-type {
      margin-bottom: 1rem;
    }

    li {
      list-style: none;
      .registration-talents__value {
        display: flex;
        font-weight: 800;
        font-size: 16.8px;
        color: #000000;
      }

      .registration-talents__workable::before {
        height: 12px;
        width: 12px;
        background-color: #412d86;
        border-radius: 50%;
        display: flex;
        content: "";
        margin-right: 10px;
        margin-top: 7px;
      }

      .registration-talents__signup::before {
        height: 12px;
        width: 12px;
        background-color: #fac328;
        border-radius: 50%;
        display: flex;
        content: "";
        margin-right: 10px;
        margin-top: 7px;
      }

      .registration-talents__key {
        display: block;
        color: #8f8d9a;
        font-weight: 300;
        font-size: 16.8px;
        color: #666666;
      }
    }
  }
}
</style>