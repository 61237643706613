<template>
  <div>
    <div class="assessment-report__questions">
      <div class="position-relative">
        <h3 class="headerBorder mb-4" v-if="isResponse">
          <span> {{ $t("assesmentReport.detailedSubmissionReport") }} </span>
        </h3>
        <div
          class="d-flex align-items-center justify-content-start"
          v-if="!isResponse"
        >
          <skeleton-loader-vue
            type="rect"
            :width="300"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
      </div>

      <div class="d-flex justify-content-between mb-3">
        <div
          class="assessment-report__questions__type mt-2"
          v-if="isResponse && assessments.mcq_assessment"
        >
          {{ $t("assesmentReport.multipleChoiceQuestion") }}
        </div>
        <div
          class="d-flex align-items-center justify-content-start mt-4"
          v-if="!isResponse"
        >
          <skeleton-loader-vue
            type="rect"
            :width="300"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        <div class="assessment-report__questions__count" v-if="false">
          {{ $t("assesmentReport.questionsAttempted") }}:
          {{ assessments.mcq_assessment.answers.length }}
          {{ $t("assesmentReport.of") }}
          {{ assessments.mcq_assessment.answers.length }}
        </div>
      </div>

      <div
        class="d-flex assessment-report__questions__dropdown"
        v-if="isResponse"
      >
        <div
          class="assessment-report__questions__number"
          v-if="isResponse && assessments.mcq_assessment"
        >
          # {{ $t("assesmentReport.questions") }} ({{
            assessments.mcq_assessment.answers.length
          }})
        </div>

        <div class="assessment-report__questions__details">
          <div class="assessment-report__questions__result" v-if="false">
            {{ $t("assesmentReport.result") }}
          </div>
          <div
            class="assessment-report__questions__score"
            v-if="isResponse && assessments.mcq_assessment"
          >
            {{ $t("assesmentReport.score") }} ({{
              assessments.mcq_assessment.score
            }}/{{ assessments.mcq_assessment.max_score }})
          </div>

          <div class="vr" style="height: 22px" v-if="false"></div>

          <div class="assessment-report__questions__toggle" v-if="false">
            <img src="@/assets/images/icons/ic_arrow-dropdown_8.svg" />
          </div>
        </div>
      </div>

      <div
        class="d-flex assessment-report__questions__dropdown"
        v-if="!isResponse"
      >
        <div
          class="d-flex align-items-center justify-content-start"
          v-if="!isResponse"
        >
          <skeleton-loader-vue
            type="rect"
            :width="300"
            :height="30"
            animation="fade"
            rounded
          />
        </div>

        <div class="assessment-report__questions__details">
          <div
            class="d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              type="rect"
              :width="200"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
      </div>

      <div class="d-flex assessment-report__questions__list" v-if="!isResponse">
        <div class="d-flex align-items-center justify-content-start">
          <skeleton-loader-vue
            type="rect"
            :width="800"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        <div class="d-flex align-items-center justify-content-start mt-4">
          <skeleton-loader-vue
            type="rect"
            :width="800"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        <div class="d-flex align-items-center justify-content-start mt-4">
          <skeleton-loader-vue
            type="rect"
            :width="800"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        <div class="d-flex align-items-center justify-content-start mt-4">
          <skeleton-loader-vue
            type="rect"
            :width="800"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
      </div>
      <div
        class="d-flex flex-column"
        v-if="isResponse && assessments.mcq_assessment"
      >
        <div
          class="d-flex assessment-report__questions__list"
          v-for="(answer, i) in assessments.mcq_assessment.answers"
          :key="i"
        >
          <div class="assessment-report__questions__list-header">
            <div class="assessment-report__question-number">{{ i + 1 }}</div>
            <div
              class="assessment-report__question-text"
              v-html="answer.question"
            ></div>
          </div>

          <div class="assessment-report__question-score">
            <img
              v-if="answer.is_right_answer"
              src="@/assets/images/icons/ic_right.svg"
              alt=""
            />
            <img v-else src="@/assets/images/icons/ic_wrong.svg" alt="" />
          </div>

          <div class="assessment-report__questions__list-body" v-if="false">
            <div class="assessment-report__questions__list-body-tittle">
              Code
            </div>
            <code> x = 5; y = 6; z = x + y; </code>
          </div>

          <div class="assessment-report__questions__list-body" v-if="false">
            <div class="assessment-report__questions__list-body-tittle">
              Output
            </div>
            <ul>
              <li>0</li>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
            </ul>
          </div>

          <div class="assessment-report__questions__list-body">
            <div class="assessment-report__questions__list-body-tittle">
              {{ $t("assesmentReport.options") }}
            </div>
            <ul>
              <li v-for="option in answer.options" :key="option">
                {{ option }}
                <span>
                  <img
                    v-if="answer.answer && answer.answer == option"
                    src="@/assets/images/icons/ic_right.svg"
                    alt=""
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between mb-3">
        <div
          class="assessment-report__questions__type mt-4"
          v-if="isResponse && assessments.code_assessment"
        >
          {{ $t("assesmentReport.problemSolving") }}
          {{ $t("assesmentReport.questions") }}
        </div>
        <div
          class="d-flex align-items-center justify-content-start mt-4"
          v-if="!isResponse"
        >
          <skeleton-loader-vue
            type="rect"
            :width="300"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        <div class="assessment-report__questions__count" v-if="false">
          {{ $t("assesmentReport.questionsAttempted") }}:
          {{ assessments.code_assessment.answers.length }}
          {{ $t("assesmentReport.of") }}
          {{ assessments.code_assessment.answers.length }}
        </div>
      </div>

      <div
        class="d-flex assessment-report__questions__dropdown"
        v-if="isResponse && assessments.code_assessment"
      >
        <div
          class="assessment-report__questions__number"
          v-if="isResponse && assessments.code_assessment"
        >
          # {{ $t("assesmentReport.questions") }} ({{
            assessments.code_assessment.answers.length
          }})
        </div>

        <div class="assessment-report__questions__details">
          <div class="assessment-report__questions__result" v-if="false">
            {{ $t("assesmentReport.result") }}
          </div>
          <div
            class="assessment-report__questions__score"
            v-if="isResponse && assessments.code_assessment"
          >
            {{ $t("assesmentReport.score") }} ({{
              assessments.code_assessment.score
            }}/{{ assessments.code_assessment.max_score }})
          </div>

          <div class="vr" style="height: 22px" v-if="false"></div>

          <div class="assessment-report__questions__toggle" v-if="false">
            <img src="@/assets/images/icons/ic_arrow-dropdown_8.svg" />
          </div>
        </div>
      </div>

      <div
        class="d-flex assessment-report__questions__dropdown"
        v-if="!isResponse"
      >
        <div
          class="d-flex align-items-center justify-content-start"
          v-if="!isResponse"
        >
          <skeleton-loader-vue
            type="rect"
            :width="300"
            :height="30"
            animation="fade"
            rounded
          />
        </div>

        <div class="assessment-report__questions__details">
          <div
            class="d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              type="rect"
              :width="200"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
      </div>

      <div class="d-flex assessment-report__questions__list" v-if="!isResponse">
        <div class="d-flex align-items-center justify-content-start">
          <skeleton-loader-vue
            type="rect"
            :width="800"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        <div class="d-flex align-items-center justify-content-start mt-4">
          <skeleton-loader-vue
            type="rect"
            :width="800"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        <div class="d-flex align-items-center justify-content-start mt-4">
          <skeleton-loader-vue
            type="rect"
            :width="800"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        <div class="d-flex align-items-center justify-content-start mt-4">
          <skeleton-loader-vue
            type="rect"
            :width="800"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
      </div>

      <div
        class="d-flex flex-column"
        v-if="isResponse && assessments.code_assessment"
      >
        <div
          class="d-flex assessment-report__questions__list"
          v-for="(answer, i) in assessments.code_assessment.answers"
          :key="i"
        >
          <div class="assessment-report__questions__list-header">
            <div class="assessment-report__question-number">{{ i + 1 }}</div>
            <div
              class="assessment-report__question-text"
              v-html="answer.question"
            ></div>
          </div>

          <div class="assessment-report__question-score">
            <img
              v-if="answer.is_right_answer"
              src="@/assets/images/icons/ic_right.svg"
              alt=""
            />
            <img v-else src="@/assets/images/icons/ic_wrong.svg" alt="" />
          </div>

          <div
            class="assessment-report__questions__list-body"
            v-if="answer.answer"
          >
            <div class="assessment-report__questions__list-body-tittle">
              {{ $t("assesmentReport.code") }}
            </div>
            <div v-text="answer.answer"></div>
          </div>

          <div class="assessment-report__questions__list-body" v-if="false">
            <div class="assessment-report__questions__list-body-tittle">
              Output
            </div>
            <ul>
              <li>0</li>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
            </ul>
          </div>

          <div class="assessment-report__questions__list-body" v-if="false">
            <div class="assessment-report__questions__list-body-tittle">
              Options
            </div>
            <ul>
              <li v-for="option in answer.options" :key="option">
                {{ option }}
                <span>
                  <img
                    v-if="answer.answer && answer.answer == option"
                    src="@/assets/images/icons/ic_right.svg"
                    alt=""
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    assessments: {
      type: Object,
    },
    isResponse: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" >
.assessment-report__questions {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 3%);
  position: relative;
  padding: 20px;
  margin: 0px;
  margin-top: 30px;
  width: 100%;
  flex-direction: row;
  align-items: center;

  .assessment-report__questions__type {
    color: #402c83;
    font-weight: 500;
    font-size: 18px;
  }
  .assessment-report__questions__count {
    color: #818181;
    font-size: 14px;
  }

  .assessment-report__questions__dropdown {
    background: #fafafa;
    border-radius: 5rem;
    padding: 1rem 2rem;
    flex-direction: row;
    border: 1px solid #dddddd;
  }

  .assessment-report__questions__details {
    display: flex;
    margin-left: auto;
  }

  .assessment-report__questions__number {
    font-weight: 500;
    color: #5e5e5e;
    font-size: 18px;
  }

  .assessment-report__questions__result {
    font-weight: 500;
    color: #5e5e5e;
    margin: 0 2rem;
    font-size: 16px;
  }

  .assessment-report__questions__score {
    font-weight: 500;
    color: #5e5e5e;
    font-size: 16px;
  }

  .vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: #d5d5d5;
    opacity: 1;
    margin: 0 0.5rem;
  }

  .assessment-report__questions__toggle {
    cursor: pointer;
    img {
      width: 15px;
      margin: 0 1rem;
    }
  }

  .assessment-report__questions__list {
    margin-top: 2rem;
    flex-direction: column;
    border-radius: 2rem;
    padding: 2rem;
    border: 1px solid #dddddd;
  }

  .assessment-report__questions__list-header {
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-start;
  }

  .assessment-report__question-score {
    margin-left: auto;
    font-weight: 500;
    color: #5e5e5e;
    font-size: 16px;
    img {
      height: 1.5rem;
    }
  }

  .assessment-report__question-number {
    background: #fafafa;
    border-radius: 1rem;
    flex-direction: row;
    border: 1px solid #dddddd;
    display: flex;
    height: 3rem;
    width: 3rem;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
  }

  .assessment-report__question-text {
    color: #000;
    margin-left: 1.5rem;
    font-weight: 500;
    word-break: break-all;
    width: 75%;
  }

  .assessment-report__questions__list-body {
    margin-left: 4.5rem;
    margin-bottom: 1rem;

    ul {
      padding-left: 1.5rem;
    }

    ul li {
      list-style: upper-latin;
      font-weight: 500;
      color: #5e5e5e;
      font-size: 16px;
      word-break: break-word;

      span img {
        height: 1rem;
      }
    }
  }

  .assessment-report__questions__list-body-tittle {
    margin-left: auto;
    font-weight: 500;
    color: #5e5e5e;
    font-size: 16px;
    margin-bottom: 1rem;
  }

  code {
    color: #5e5e5e;
  }
}

@media (max-width: 576px) {
  .assessment-report__questions .assessment-report__questions__number {
    font-size: 16px;
  }

  .assessment-report__questions .assessment-report__questions__score {
    font-size: 13px;
  }

  .assessment-report__questions .assessment-report__questions__list-body {
    margin-left: 0;
  }

  .assessment-report__questions .assessment-report__questions__list-header {
    align-items: flex-start;
  }
  .assessment-report__questions .assessment-report__question-number {
    height: 35px;
    width: 35px;
    border-radius: 10.11px;
  }
  .assessment-report__questions .assessment-report__question-text {
    margin-left: 1rem;
  }
}
</style>