<template>
  <div class="assessment-report__wrapper">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3 class="assessment-report__wrapper__title" v-if="isResponse">
            {{ $t("assesmentReport.assesmentReport") }}
          </h3>
          <div
            style="margin-top: 30px"
            class="d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              type="rect"
              :width="300"
              :height="30"
              animation="fade"
              rounded
            />
          </div>

          <basicInfo
            :profile="profile"
            :assessments="assessments"
            :isResponse="isResponse"
          />

          <chartsData :assessments="assessments" :isResponse="isResponse" />

          <testTimeAnalysis
            :isResponse="isResponse"
            :assessments="assessments"
          />

          <questions :assessments="assessments" :isResponse="isResponse" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import basicInfo from "@/pages/Client/assessmentReport/components/basicInfo.vue";
import chartsData from "@/pages/Client/assessmentReport/components/chartsData";
import testTimeAnalysis from "@/pages/Client/assessmentReport/components/testTimeAnalysis";
import questions from "@/pages/Client/assessmentReport/components/questions";
import { scrollToTop } from "@/utils/utils.js";

export default {
  components: {
    basicInfo,
    chartsData,
    testTimeAnalysis,
    questions,
  },
  data: () => {
    return {
      isResponse: false,
      assessments: null,
      profile: null,
    };
  },
  mounted() {
    const normalId = this.$route.params.id;
    this.getAssessmentData(normalId);
    this.scrollToTop();
  },
  methods: {
    scrollToTop,
    async getAssessmentData(id) {
      try {
        const getTalentAssessments = await this.axios
          .get(`api/assessments/reports/${id}`)
          .then((res) => {
            this.assessments = res.data.data;
          });

        const getTalentProfile = this.axios
          .get(`/api/client/users/${this.assessments.talent_sharable_code}`)
          .then((res) => {
            this.profile = res.data.data;
          })
          .catch((err) => {
            console.log("err :>> ", err);
          });

        Promise.all([getTalentAssessments, getTalentProfile]).then(() => {
          this.isResponse = true;
        });
      } catch (error) {
        if (
          error?.response?.status == 403 ||
          error?.response?.status == 404 ||
          error?.response?.status == 401
        ) {
          this.$router.replace("/404");
        }
      }
    },
  },
};
</script>

<style lang="scss">
.assessment-report__wrapper__title {
  margin-top: 30px;
  font-size: 22px;
  font-weight: 600;
}
</style>