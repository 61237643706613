<template>
  <div class="assessment-report__test-time">
    <div class="position-relative">
      <h3 class="headerBorder mb-4" v-if="isResponse">
        <span>
          {{ $t("assesmentReport.testTimeAnalysis") }}
        </span>
      </h3>
      <div
        class="d-flex align-items-center justify-content-start"
        v-if="!isResponse"
      >
        <skeleton-loader-vue
          type="rect"
          :width="300"
          :height="30"
          animation="fade"
          rounded
        />
      </div>
    </div>

    <div class="d-flex w-100 mt-2">
      <div class="row vdivide w-100" v-if="isResponse">
        <div class="col-md-5 col-sm-12" v-if="assessments.mcq_assessment">
          <h5>{{ $t("assesmentReport.mcq") }}</h5>
          <div
            class="assessment-report__test-time__box"
            v-if="isResponse && assessments.mcq_assessment.invited_at"
          >
            <div class="assessment-report__test-time__box__tittle">
              {{ $t("assesmentReport.testInviteTime") }}
            </div>
            <div class="assessment-report__test-time__box__date">
              {{ getData(assessments.mcq_assessment.invited_at) }}
            </div>
          </div>

          <div
            class="assessment-report__test-time__box"
            v-if="isResponse && assessments.mcq_assessment.started_at"
          >
            <div class="assessment-report__test-time__box__tittle">
              {{ $t("assesmentReport.testStartTime") }}
            </div>
            <div class="assessment-report__test-time__box__date">
              {{ getData(assessments.mcq_assessment.testStartTime) }}
            </div>
          </div>

          <div
            class="assessment-report__test-time__box"
            v-if="isResponse && assessments.mcq_assessment.duration"
          >
            <div class="assessment-report__test-time__box__tittle">
              {{ $t("assesmentReport.teastDuration") }}
            </div>
            <div class="assessment-report__test-time__box__date">
              {{ assessments.mcq_assessment.duration }}
            </div>
          </div>

          <div
            class="assessment-report__test-time__box"
            v-if="isResponse && assessments.mcq_assessment.submitted_at"
          >
            <div class="assessment-report__test-time__box__tittle">
              {{ $t("assesmentReport.testEndTime") }}
            </div>
            <div class="assessment-report__test-time__box__date">
              {{ getData(assessments.mcq_assessment.submitted_at) }}
            </div>
          </div>
        </div>
        <div class="col"></div>
        <div class="col-md-5 col-sm-12" v-if="assessments.code_assessment">
          <h5>
            {{ $t("assesmentReport.problemSolving") }}
          </h5>
          <div
            class="assessment-report__test-time__box"
            v-if="isResponse && assessments.code_assessment.invited_at"
          >
            <div class="assessment-report__test-time__box__tittle">
              {{ $t("assesmentReport.testInviteTime") }}
            </div>
            <div class="assessment-report__test-time__box__date">
              {{ getData(assessments.code_assessment.invited_at) }}
            </div>
          </div>

          <div
            class="assessment-report__test-time__box"
            v-if="isResponse && assessments.code_assessment.started_at"
          >
            <div class="assessment-report__test-time__box__tittle">
              {{ $t("assesmentReport.testStartTime") }}
            </div>
            <div class="assessment-report__test-time__box__date">
              {{ getData(assessments.code_assessment.started_at) }}
            </div>
          </div>

          <div
            class="assessment-report__test-time__box"
            v-if="isResponse && assessments.code_assessment.duration"
          >
            <div class="assessment-report__test-time__box__tittle">
              {{ $t("assesmentReport.teastDuration") }}
            </div>
            <div class="assessment-report__test-time__box__date">
              {{ assessments.code_assessment.duration }}
            </div>
          </div>

          <div
            class="assessment-report__test-time__box"
            v-if="isResponse && assessments.code_assessment.submitted_at"
          >
            <div class="assessment-report__test-time__box__tittle">
              {{ $t("assesmentReport.testEndTime") }}
            </div>
            <div class="assessment-report__test-time__box__date">
              {{ getData(assessments.code_assessment.submitted_at) }}
            </div>
          </div>
        </div>
      </div>
      <div class="row w-100" v-if="!isResponse">
        <div class="col-md-6 col-sm-12">
          <h5>
            <div class="d-flex align-items-center justify-content-start mt-4">
              <skeleton-loader-vue
                type="rect"
                :width="500"
                :height="30"
                animation="fade"
                rounded
              />
            </div>
          </h5>

          <div class="d-flex align-items-center justify-content-start mt-4">
            <skeleton-loader-vue
              type="rect"
              :width="500"
              :height="30"
              animation="fade"
              rounded
            />
          </div>

          <div class="d-flex align-items-center justify-content-start mt-4">
            <skeleton-loader-vue
              type="rect"
              :width="500"
              :height="30"
              animation="fade"
              rounded
            />
          </div>

          <div class="d-flex align-items-center justify-content-start mt-4">
            <skeleton-loader-vue
              type="rect"
              :width="500"
              :height="30"
              animation="fade"
              rounded
            />
          </div>

          <div class="d-flex align-items-center justify-content-start mt-4">
            <skeleton-loader-vue
              type="rect"
              :width="500"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <h5>
            <div class="d-flex align-items-center justify-content-start mt-4">
              <skeleton-loader-vue
                type="rect"
                :width="500"
                :height="30"
                animation="fade"
                rounded
              />
            </div>
          </h5>

          <div class="d-flex align-items-center justify-content-start mt-4">
            <skeleton-loader-vue
              type="rect"
              :width="500"
              :height="30"
              animation="fade"
              rounded
            />
          </div>

          <div class="d-flex align-items-center justify-content-start mt-4">
            <skeleton-loader-vue
              type="rect"
              :width="500"
              :height="30"
              animation="fade"
              rounded
            />
          </div>

          <div class="d-flex align-items-center justify-content-start mt-4">
            <skeleton-loader-vue
              type="rect"
              :width="500"
              :height="30"
              animation="fade"
              rounded
            />
          </div>

          <div class="d-flex align-items-center justify-content-start mt-4">
            <skeleton-loader-vue
              type="rect"
              :width="500"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    isResponse: {
      type: Boolean,
    },
    assessments: {
      type: Object,
    },
  },
  methods: {
    getData(data) {
      if (data)
        return `${moment(data).format("MMM DD YYYY")} at ${moment(data).format(
          "h:mm:ss A z"
        )} GMT `;
    },
  },
};
</script>

<style lang="scss">
.assessment-report__test-time {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 3%);
  position: relative;
  padding: 20px;
  margin: 0px;
  margin-top: 30px;
  width: 100%;
  flex-direction: row;
  align-items: center;

  .assessment-report__test-time__box {
    margin-bottom: 2rem;
    .assessment-report__test-time__box__tittle {
      color: #ababab;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;

      &::before {
        height: 10px;
        width: 10px;
        background-color: #ababab;
        content: "";
        display: flex;
        margin-right: 0.5rem;
      }
    }

    .assessment-report__test-time__box__date {
      color: #000;
      margin-left: 1.5rem;
      font-weight: 500;
    }
  }
}

@media (max-width: 576px) {
  .assessment-report__test-time .assessment-report__test-time__box {
    margin-bottom: 1rem;
  }

  .assessment-report__test-time
    .assessment-report__test-time__box
    .assessment-report__test-time__box__tittle {
    font-size: 19px;
  }
  .assessment-report__test-time
    .assessment-report__test-time__box
    .assessment-report__test-time__box__date {
    font-size: 16px;
  }
}
</style>